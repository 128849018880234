<template>

<app-panel v-on:close="$emit('close')">
		
	<app-panel-title :text="'Refund ' + noun" />

	<app-panel-text>

		Are you sure you wish to refund <b>{{ name }}</b>? Once confirmed it cannot be undone.

	</app-panel-text>

	<app-panel-text>

		The customer will receive an e-mailed receipt of the refund details and the funds will arrive in their bank account within 2-5 working days.

	</app-panel-text>

	<app-panel-title text="Options" />

	<app-panel-check text="Subtract discount from refund" :active="model.discount" v-on:toggle="model.discount = !model.discount" v-if="sale.cost.discount" />
	<app-panel-check text="Delete any linked badges or addons" :active="model.delete" v-on:toggle="model.delete = !model.delete" />

	<app-panel-content>

		<app-input-text label="Refund message" :autogrow="true" v-model="model.message" :stacked="true" placeholder="Enter optional message to be included with e-mailed receipt" />

	</app-panel-content>

	<app-panel-content>

		<app-button text="Confirm" :loading="is.loading" v-on:click="onConfirmClick" />

	</app-panel-content>

</app-panel>

</template>

<script>

export default {

	props: ['item', 'name', 'sale', 'api'],

	data: function() {

		return {
			is: {
				loading: false
			},
			model: {
				discount: true,
				delete: false,
				message: ''
			}
		}

	},

	created: function() {

		this.$pubsub.$emit('offset', true)
		
	},

	beforeDestroy: function() {

		this.$pubsub.$emit('offset', false)

	},

	computed: {

		noun: function() {

			return (this.item === true) ? 'sale' : 'item'
			
		}

	},

	methods: {

		onConfirmClick: function() {

			this.is.loading = true

			this.$api.post(this.api, {
				item: (this.item === true) ? false : this.item,
				delete: this.model.delete,
				refund: this.model.refund,
				message: this.model.message
			}).then(function(json) {

				this.is.loading = false

				this.$notify({
					message: ['You have successfully refunded the ' + this.noun + '.']
				})

				this.$emit('refunded', json)

				this.$emit('close')

			}.bind(this))

		}

	}

}

</script>

<style scoped>

</style>
